'use strict';

(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), 'event': 'gtm.js'
    }); let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.defer = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    j.addEventListener('load', function () {
        let _ge = new CustomEvent('gtm_loaded', { 'bubbles': true });
        d.dispatchEvent(_ge);
        window.scriptOnLoad();
    });
    f.parentNode.insertBefore(j, f);
}(window, document, 'script', 'dataLayer', window.tagManagerCode));